<template>
  <div class="bg-white">
    <section class="max-w-6xl mx-auto px-4 relative z-10 bg-white">
      <div
        class="flex inset-0 items-center justify-center inline-block relative"
      >
        <img src="@/assets/NG-logo.jpg" class="w-64 mx-auto h-auto" />
      </div>
      <p class="mb-4 text-base text-center pt-3.5">
        Find our football predictions, odds and match information below.<template v-if="numberMatches && numberCompetitions"> There
        are {{ numberMatches }} matches today across {{ numberCompetitions }}
        {{ competitionsString }}.</template><br>
        <u><br><span style="color:blue;text-transform: uppercase;font-size: 13px;"><a href="/why#howtouse">Still unsure on how to use Numbers Game? Check our guide here</a></span></u> 🤔<br>
        <span v-if="subType == 'active'">
          <u><br><span style="color:blue;text-transform: uppercase;font-size: 13px;"><a href="https://discord.gg/yyk2vvnTsr">Join the Numbers Game discord for exclusive picks, advice and tips.</a></span></u><p class="inline">&nbsp;</p><img src="@/assets/icons/discord.png" class="w-6 mx-auto h-auto inline" /><br>
          <u><br><span style="color:blue;text-transform: uppercase;font-size: 13px;"><a href="https://fantasy.premierleague.com/leagues/auto-join/py0zbv">Join our FPL league for regular giveaways.</a></span></u> ✨
        </span>
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "SubscriberIntro",
  props: ["numberOfMatches", "numberOfCompetitions", "subscriptionType"],
  computed: {
    numberMatches() {
      return this.numberOfMatches;
    },
    numberCompetitions() {
      return this.numberOfCompetitions;
    },
    subType() {
      return this.subscriptionType;
    },
    competitionsString() {
      return this.numberOfCompetitions == 1 ? "competition" : "competitions";
    },
  },
};
</script>
